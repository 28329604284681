import { Grid } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { WinCustomNumberInput } from "../";
import { WIN_QUICK_BET_POINT } from "../../config";
import { thousandFormat } from "../../plugins/numberFormat";
import styles from "./style.module.scss";

const LaunchBetModalContent = ({ colorStyle = {}, totalMoney = 0, setTotalMoney = () => {} }) => {
  const { t } = useTranslation();
  const [selectedMoney, setSelectedMoney] = useState();

  const selectedMoneyStyle = (item) => {
    if (selectedMoney === item) {
      return { color: "#ffff", ...colorStyle };
    }
    return {};
  };

  const onSelectedMoney = (money) => {
    setSelectedMoney(money);
    setTotalMoney(money);
  };

  const customInputStyle = {
    "--placeholder-color": "rgba(255, 255, 255, 0.6)",
    boxShadow: " 0px 0px 4px rgba(0, 255, 163, 0.48)",
    backgroundColor: "#171b2a",
    "--color": "#fff",
    border: "1px solid #212328",
  };

  return (
    <>
      <div className={styles.moneyTitle}>{t("win.betpopup.betAmount")}</div>
      <WinCustomNumberInput
        placeholder={t("win.betpopup.placeholder")}
        inputStyle={customInputStyle}
        value={totalMoney}
        onChange={(val) => {
          setTotalMoney(val);
          setSelectedMoney({ key: val.toString(), value: val });
        }}
      />
      <div className={styles.moneyTitle}>{t("win.betpopup.quickSelect")}</div>
      <Grid columns={6} gap={12}>
        {WIN_QUICK_BET_POINT.map((item, index) => {
          return (
            <Grid.Item key={`${item}_${index}`}>
              <div
                onClick={() => onSelectedMoney(item)}
                className={styles.moneySelector}
                style={selectedMoneyStyle(item)}
              >
                {thousandFormat(item)}
              </div>
            </Grid.Item>
          );
        })}
      </Grid>
    </>
  );
};

export default LaunchBetModalContent;
