import "./App.css";
import "./plugins/i18n";
// import "antd/dist/antd.css";
import "animate.css";
import "antd/dist/antd.min.css";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { httpClient } from "./apis/https";
import FontSetting from "./components/FontSetting";
import { ENV, GCODE, PRODUCTION_NAME_DEV } from "./config";
import Context from "./context";
import { platformAdScript, platformScriptId } from "./externalScript";
import RouterRender from "./router";

const currentTheme = require(`./theme/${PRODUCTION_NAME_DEV}/theme.json`);
const defaultTheme = require(`./theme/defaultTheme.json`);
const finalTheme = { ...defaultTheme, ...currentTheme };

function App() {
  const { t } = useTranslation();
  const [vh, setVh] = useState(1);
  const setTheme = () => {
    const r = document.querySelector(":root");
    const final = Object.entries(finalTheme);
    final.map((color) => {
      r.style.setProperty(color[0], color[1]);
    });
  };
  useEffect(() => {
    setTheme();
  }, [finalTheme]);

  const initGTAG = () => {
    if (GCODE === "" || GCODE === null || GCODE === undefined) return;
    TagManager.initialize({
      gtmId: GCODE,
    });
  };

  const initStyle = () => {
    let _vh = window.innerHeight * 0.01;
    setVh(_vh);
    window.document.documentElement.style.setProperty("--vh", `${_vh}px`);
    window.document.documentElement.style.setProperty("background", `#f5f5f5`);
  };

  useEffect(() => {
    initStyle();
    initGTAG();
  }, []);

  /**
   * SCROLL TESTING PURPOSE
   * DO NOT DELETE, OPEN WHEN DEBUG SCROLL ISSUE ONLY
   */
  // window.addEventListener(
  //   "scroll",
  //   function (e) {
  //     var el = e.target;
  //     while (el && el !== document && !isScrollable(el)) {
  //       el = el.parent;
  //     }
  //     console.debug("Scrolled element: " + (el.className || "document"));
  //   },
  //   true,
  // );

  // function isScrollable(el) {
  //   return el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;
  // }

  const renderScript = () => {
    if (platformScriptId[PRODUCTION_NAME_DEV] && platformScriptId[PRODUCTION_NAME_DEV][ENV]) {
      return platformScriptId[PRODUCTION_NAME_DEV][ENV].map((_script) => {
        const scriptKey = _script.scriptKey;
        const id = _script.id;
        switch (scriptKey) {
          //JUST IN CASE THERE IS OTHER SCRIPT WITH DIFFERENT WAY OF INTEGRATION,PUT INSIDE
          //THIS SWITCH-CASE FUNCTIONy

          default:
            if (platformAdScript[scriptKey] && platformAdScript[scriptKey].script) {
              return (
                <Helmet>
                  <script type="text/javascript">
                    {platformAdScript[scriptKey].script({ id: id })}
                  </script>
                </Helmet>
              );
            }
        }
      });
    }
  };
  const renderNoScript = () => {
    if (platformScriptId[PRODUCTION_NAME_DEV] && platformScriptId[PRODUCTION_NAME_DEV][ENV]) {
      return platformScriptId[PRODUCTION_NAME_DEV][ENV].map((_script) => {
        const scriptKey = _script.scriptKey;
        const id = _script.id;
        if (platformAdScript[scriptKey] && platformAdScript[scriptKey].noscript) {
          return <noscript>{platformAdScript[scriptKey].noscript({ id: id })}</noscript>;
        }
      });
    }
  };

  useEffect(() => {
    httpClient.translateFn = t;
  }, [t]);

  return (
    <div id="app" className="app">
      {renderScript()}
      <Helmet>{renderNoScript()}</Helmet>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Context>
          <FontSetting />
          <RouterRender />
        </Context>
      </SnackbarProvider>
      {/* </div> */}
    </div>
  );
}

export default App;
