import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

import { PRODUCTION_NAME_DEV } from "../../../../../config";
import { moneyFormat } from "../../../../../plugins/numberFormat";
import { copyText } from "../../../../../plugins/utils";
import Copy from "../../icons/Copy.svg";

const ColumnsContext = createContext({
  getColumns: () => {},
});
export const ColumnsContextProvider = (props) => {
  const { t, i18n } = useTranslation();
  const getLang = () => i18n.language;
  // console.log("nowLang", getLang());

  const render77ChoiceNumber = (val) => {
    const itemSorting = (a, b) => {
      if (isNaN(parseInt(a)) && isNaN(parseInt(b))) {
        return 1;
      }
      return parseInt(a) - parseInt(b);
    };

    if (typeof val === "string") {
      const valSplit = val.split("|");
      return valSplit
        .map((_val) => {
          return (
            _val
              .split(",")
              .sort(itemSorting)
              //because parseInt(7X)=7
              .map((v) =>
                v.includes("X")
                  ? t(`lottery.item.${v}`)
                  : isNaN(parseInt(v)) && v !== "_" && v !== ""
                  ? t(`lottery.item.${v}`)
                  : v,
              )
              .join(",")
          );
        })
        .join("|");
    } else {
      return val;
    }
  };

  const renderCopyableTransId = (record) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span style={{ marginRight: "12px" }}>{record.trans_id}</span>
        <img
          src={Copy}
          alt="copy icon"
          onClick={() => {
            copyText(record.trans_id, t("normal.copy.complete"));
          }}
        />
      </div>
    );
  };

  const renderGeneralWinlost = ({
    total_win_lose,
    status,
    showWinLostStatus = [],
    unsettleStatus = [],
  }) => {
    if (total_win_lose === null || unsettleStatus.includes(status)) return t("betRecord.unsettled");
    if (showWinLostStatus.length <= 0 || showWinLostStatus.includes(status)) {
      return (
        <span
          style={{
            color: total_win_lose > 0 ? "var(--ant-success-color)" : "var(--ant-error-color)",
          }}
        >
          {moneyFormat(total_win_lose)}
        </span>
      );
    }
    return "-";
  };

  const getColumns = (game) => {
    const sabaColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "debit_amount",
        render: (data) => {
          return moneyFormat(data?.debit_amount);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "earn_amount",
        render: (data) => {
          const UNSETTLE_STATUS = [2];
          const SHOW_WIN_LOSS_STATUS = [4, 5, 6, 7, 8];
          return renderGeneralWinlost({
            total_win_lose: data.earn_amount,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
            unsettleStatus: UNSETTLE_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "sport_name",
        render: (data) => {
          const detailData = data.ticket_detail?.length > 0 ? data.ticket_detail[0] : {};
          return <>{data.txns !== null ? t("betRecord.saba.parlay") : detailData?.sportTypeName}</>;
        },
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.league"),
        dataIndex: "league_name",
        collapse: true,
        render: (data) => {
          const detailData = data.ticket_detail?.length > 0 ? data.ticket_detail[0] : {};
          return <>{data.txns !== null ? t("betRecord.saba.parlay") : detailData?.leagueName}</>;
        },
      },
      {
        title: t("betRecord.homeTeam"),
        dataIndex: "home_name",
        collapse: true,
        render: (data) => {
          const detailData = data.ticket_detail?.length > 0 ? data.ticket_detail[0] : {};
          return <>{data.txns !== null ? t("betRecord.saba.parlay") : detailData?.homeName}</>;
        },
      },
      {
        title: t("betRecord.awayTeam"),
        dataIndex: "away_name",
        collapse: true,
        render: (data) => {
          const detailData = data.ticket_detail?.length > 0 ? data.ticket_detail[0] : {};
          return <>{data.txns !== null ? t("betRecord.saba.parlay") : detailData?.awayName}</>;
        },
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return <>{t(`betRecord.saba.status${data.status}`)}</>;
        },
      },
    ];
    const blockchainColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "stake",
        render: (data) => {
          return moneyFormat(data?.stake);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "winlost_amount",

        render: (data) => {
          return renderGeneralWinlost({
            total_win_lose: data.winlost_amount,
            status: data.status,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "game_code",
        render: (data) => {
          return t([
            `betRecord.games.${PRODUCTION_NAME_DEV}.${data.game_code}`,
            `betRecord.games.${data.game_code}`,
          ]);
        },
      },
      {
        title: t("betRecord.period"),
        dataIndex: "period",
        collapse: true,
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const bglotteryColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_amount",
        render: (data) => {
          return moneyFormat(data?.bet_amount);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "payout",
        render: (data) => {
          return renderGeneralWinlost({
            total_win_lose: data.payout,
            status: data.order_status,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "lottery_name",
      },
      {
        title: t("betRecord.period"),
        dataIndex: "issue",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "order_status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.bglottery.status${data.order_status}`);
        },
      },
    ];
    const lottery77Columns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "total_bet_point",
        render: (data) => {
          return moneyFormat(data?.total_bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "game_type",
        render: (data) => {
          return t(`betRecord.77lottery.gameType.${data.game_type}`);
        },
      },
      {
        title: t("game.winlottery.betContent"),
        dataIndex: "choice_number",
        render: (data) => {
          return (
            <span>{`【${t(`gameWay.${data.game_way_type}`)} ${
              data.game_way_detail_type ? `-${t(`gameWay.${data.game_way_detail_type}`)}` : ""
            }】${render77ChoiceNumber(data?.choice_number)}`}</span>
          );
        },
      },
      {
        title: t("betRecord.period"),
        dataIndex: "period",
        collapse: true,
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const mgColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "game_name",
        render: (data) => {
          const nowLang = getLang();
          return nowLang === "zh" ? data.chinese_game_name : data.game_name;
        },
      },
      // {
      //   title: t("betRecord.period"),
      //   dataIndex: "period",
      //   collapse: true,
      // },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const winColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "total_bet_point",
        render: (data) => {
          return moneyFormat(data?.total_bet_point);
        },
      },
      {
        title: t("normal.handlingFee"),
        dataIndex: "fee",
        render: (data) => {
          return moneyFormat(data?.fee);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "game_type",
        render: (data) => {
          return t(`win.betRecord.wingame.gameType.${data.game_type}`);
        },
      },
      {
        title: t("game.winlottery.betContent"),
        dataIndex: "choice_number",
        render: (data) => {
          if (data.game_category_detail_type === "LOTBIT") {
            return <span>{t(`win.bitgame.bettype.${data?.choice_number}`)}</span>;
          }

          if (isNaN(parseInt(data?.choice_number)))
            return <span>{t(`win.wingame.bettype.${data?.choice_number}`)}</span>;
          return <span>{t(`${data?.choice_number}`)}</span>;
        },
      },
      {
        title: t("betRecord.resultNumber"),
        dataIndex: "lottery_number",
        render: (data) => {
          if (!data.lottery_number) return <span>{t("betRecord.resultNumber.notDrawn")}</span>;
          if (data.game_category_detail_type === "LOTBIT") {
            return JSON.parse(data.lottery_number).period_result_bit.current_amount;
          } else {
            return JSON.parse(data.lottery_number).period_result_number[0];
          }
        },
      },
      {
        title: t("betRecord.period"),
        dataIndex: "period",
        collapse: true,
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const t1Columns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "stake",
        render: (data) => {
          return moneyFormat(data?.stake);
        },
      },
      {
        title: t("game.winlottery.betContent"),
        dataIndex: "bet_content",
        render: (data) => {
          return data?.bet_content === null ? "-" : <div>{data.bet_content}</div>;
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [1, 4, 5];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "game_code",
        render: (data) => {
          return <span style={{ textTransform: "capitalize" }}>{data.game_code}</span>;
        },
      },
      {
        title: t("betRecord.period"),
        dataIndex: "period",
        collapse: true,
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.t1.status${data.status}`);
        },
      },
    ];
    const evoColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "stake",
        render: (data) => {
          return moneyFormat(data?.stake);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "game_code",
        render: (data) => {
          return t(`evo.gameType.${data.game_code}`);
        },
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.evo.status${data.status}`);
        },
      },
    ];
    const ppColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const pgColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];

    const yggColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const evoplayColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];

    const jiliColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const spribeColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const ptColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];

    const ppliveColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const fcColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const cq9Columns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const playstarColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];

    const weliveColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];

    const weblockchainColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];

    const welotteryColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const tcgColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const jilifishColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const youbetColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const aesexyColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "bet_point",
        render: (data) => {
          return moneyFormat(data?.bet_point);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "total_win_lose",
        render: (data) => {
          const SHOW_WIN_LOSS_STATUS = [0, 1, 2, 3];
          return renderGeneralWinlost({
            total_win_lose: data.total_win_lose,
            status: data.status,
            showWinLostStatus: SHOW_WIN_LOSS_STATUS,
          });
        },
      },
      {
        title: t("betRecord.game.type"),
        dataIndex: "name",
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        collapse: true,
        render: (data) => renderCopyableTransId(data),
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const defaultColumns = [
      {
        title: t("betRecord.bet"),
        dataIndex: "stake",
        render: (data) => {
          return moneyFormat(data?.stake);
        },
      },
      {
        title: t("betRecord.winAmount"),
        dataIndex: "winlost_amount",
        render: (data) => {
          return data?.winlost_amount === null ? (
            t("betRecord.unsettled")
          ) : (
            <span
              style={{
                color:
                  data?.winlost_amount > 0
                    ? "var(--color-system-success)"
                    : "var(--color-system-error)",
              }}
            >
              {moneyFormat(data?.winlost_amount)}
            </span>
          );
        },
      },
      {
        title: t("betRecord.transId"),
        dataIndex: "trans_id",
        render: (data) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "12px" }}>{data.trans_id}</span>
              <img
                src={Copy}
                alt="copy icon"
                onClick={() => {
                  copyText(data.trans_id, t("normal.copy.complete"));
                }}
              />
            </div>
          );
        },
      },
      {
        title: t("betRecord.settleTime"),
        dataIndex: "settlement_time",
        collapse: true,
      },
      {
        title: t("betRecord.status"),
        dataIndex: "status",
        collapse: true,
        render: (data) => {
          return t(`betRecord.general.status${data.status}`);
        },
      },
    ];
    const columnsDict = {
      saba: sabaColumns,
      blockchain: blockchainColumns,
      bglottery: bglotteryColumns,
      "77lottery": lottery77Columns,
      winlottery: winColumns,
      mg: mgColumns,
      t1: t1Columns,
      evo: evoColumns,
      pp: ppColumns,
      pg: pgColumns,
      ygg: yggColumns,
      evoplay: evoplayColumns,
      jili: jiliColumns,
      jdb: spribeColumns,
      pt: ptColumns,
      pplive: ppliveColumns,
      fc: fcColumns,
      cq9: cq9Columns,
      playstar: playstarColumns,
      welive: weliveColumns,
      weblockchain: weblockchainColumns,
      welottery: welotteryColumns,
      jilifish: jilifishColumns,
      tcg: tcgColumns,
      youbet: youbetColumns,
      aesexy: aesexyColumns,
      default: defaultColumns,
    };
    return columnsDict[game] ?? defaultColumns;
  };

  return <ColumnsContext.Provider value={{ getColumns }}>{props.children}</ColumnsContext.Provider>;
};

// export default Columns;
export const useGetColumns = () => {
  const _columnsContext = useContext(ColumnsContext);
  return _columnsContext;
};
