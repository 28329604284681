import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";
import styles from "./style.module.scss";
const AppBar = ({
  onClickEvent,
  title,
  titleEllipsis,
  iconClosePadding,
  renderTitleIcon,
  showBackButton = true,
  style = {},
  winColorAppBarHeader = "",
  winColorAppBarTitleBox = "",
  winColorAppBarTitle = "",
  winColorLeftOutlinedColor = false,
}) => {
  return (
    <>
      <div className={`${styles.appBarHeader} ${winColorAppBarHeader}`} style={{ ...style }}>
        <Row style={{ height: "50px" }}>
          <Col span={5} style={{ display: "flex", alignItems: "center" }}>
            {showBackButton ? (
              <Button
                onClick={onClickEvent}
                type="text"
                icon={
                  <LeftOutlined
                    style={{
                      color: winColorLeftOutlinedColor
                        ? "var(--gamewin-winColor-topBar-text)"
                        : "var(--color-topbar-icon)",
                    }}
                  />
                }
              />
            ) : null}
          </Col>
          <Col span={14}>
            <div className={`${styles.appBarTitleBox} ${winColorAppBarTitleBox}`}>
              <span
                className={`${styles.appBarTitle} ${
                  titleEllipsis ? styles.appBarTitleEllipsis : ""
                } ${winColorAppBarTitle}`}
              >
                {title}
              </span>
            </div>
          </Col>
          <Col
            span={5}
            style={{
              textAlign: "right",
              paddingRight: iconClosePadding ?? "",
              paddingRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {renderTitleIcon ? renderTitleIcon() : <></>}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AppBar;
