
const CDN_URL = "https://dl.biglotto.game";
const GLOBAL_CDN = "https://dl-game.biglotto.game";
const GLOBAL_SETTING_API = "globalsetting.biglotto.game";
const GLOBAL_USER_API = "globaluser.biglotto.game";
const IMAGE_API = "image.biglotto.game";
const MAIN_API = "api.biglotto.game";
const WHEEL_API = "api.biglotto.game";
const PC_URL = "";
const WIN_URL = "gamewinlotteryapi.biglotto.game";
const HISTORY_URL = "gamehistory.biglotto.game";
const PROTOCOL = "https";
const AGENT_CENTER_URL = "https://188agentweb.biglotto.game";
const AGENT_MOBILE_URL = "https://188agent.biglotto.game/";
const GAME_SOCKET_URL = "gamewinlotterydata.biglotto.game/ws";
const STOCK_SOCKET_URL = "game77lotterydata. biglotto.game/ws";
const WS_PROTOCOL = "wss";
const DOUBLE_SEVEN_MOBILE_URL = "https://game77lottery.biglotto.game";
const MG_SLOT_MAIN_API = "gamemgapi.biglotto.game";
const T1_MAIN_API = "gamet1api.biglotto.game";
const EVO_MAIN_API = "gameevoapi.biglotto.game";
const PP_SLOT_MAIN_API = "gameppapi.biglotto.game";
const PG_SLOT_MAIN_API = "gamepgapi.biglotto.game";
const YGG_SLOT_MAIN_API = "gameyggapi.biglotto.game";
const EVOPLAY_SLOT_MAIN_API = "gameevoplayapi.biglotto.game";
const JILI_SLOT_MAIN_API = "gamejiliapi.biglotto.game";
const DOUBLE_SEVEN_MAIN_API = "game77lotteryapi.biglotto.game";
const GALAXSYS_MAIN_API = "gamegalaxsysapi.biglotto.game";
const SPRIBE_MAIN_API = "gamejdbapi.biglotto.game";
const PT_MAIN_API = "gameptapi.biglotto.game";
const PPLIVE_MAIN_API = "gameppliveapi.biglotto.game";
const ONEPLAY_MAIN_API = "gameoneapi.biglotto.game";
const PLAYSTAR_MAIN_API = "gameplaystarapi.biglotto.game";
const WEBLOCKCHAIN_MAIN_API = "gameweapi.biglotto.game";
const WELOTTERY_MAIN_API = "gameweapi.biglotto.game";
const WELIVE_MAIN_API = "gameweapi.biglotto.game";
const TCG_MAIN_API = "gametcgapi.biglotto.game";
const YOUBET_MAIN_API = "gameyoubetapi.biglotto.game";
const AESEXY_MAIN_API = "gameaesexyapi.biglotto.game";
const SPRIBEONE_MAIN_API = {"dev":"gamespribeoneapidev.meta188.cc/api","stag":"gamespribeoneapi.ball188.cc","prod":"gamespribeoneapi.biglotto.game"};
const DECIMAL_SEPARATOR = '.';
const DEFAULT_CURRENCY = 'inr';
const TIMEZONE_CURRENCY = {};
const DEFAULT_LANG = 'en';
const FIXED_NUMBER = 5;
const MIN_DECIMAL_POINT = 2;
const MAX_DECIMAL_POINT = 2;
const HOME_HEADER_BACKGROUND_IMAGE = '/images/logo/cc188/background_header.png';
const BINANCE_URL = "https://api.binance.com/api/v3";
const PRODUCTION_NAME_DEV = 'bl';
const PRODUCTION_NAME_DISPLAY = 'Big Lotto';
const BASE_PROJECT = ["bl","headerleft","accounthorizontal","topmenu","footerround","1bbetv2"]
const THOUSANDS_SEPARATOR = ',';
const HEADER_SHOW_TRANS_RECORD = true;
const ADD_PADDING_BOTTOM = '72px';
const HIDE_VIP_CATEGORY = []
const EXTERNAL_GAME = []
const ACCOUNT_VERIFY_METHOD = ["email","phone"]
const GCODE = "GTM-N5RMX5HQ";
const DOWNLOAD_PAGE_LOGO_FILE_EXTENTION = 'png';
const IS_WALLET_IN_PERSONAL_PAGE = true;
const HIDE_OFFLINE_BANK_CARD_DEPOSIT = true;
const HIDE_INHOUSE_CRYPTO = false;
const TELEGRAM_URL = "";
const LAST_PLAY_COUNT = 3;
const GOOGLE_PLAY_DOWNLOAD_URL = '-';
const APPLICATION_VERSION = "3.2.6";
const SHOW_WITHDRAW_LIST = false;
const SHOW_PROMOTION_DATE = true;
const MIN_USDT_WITHDRAW_LIMIT = 0;
const MAX_USDT_WITHDRAW_LIMIT = 0;
const DOUBLE7_LOTTERY_SHOW_POPUP = true;
const HIDE_PROFILE_PHONE = false;
const SHOW_DEPOSIT_PROMO_IMAGE = false;
const WIN_DISABLE_BET_TIME = 5;
const SHOW_DAILY_PROMO = false;
const FORCE_OPEN_DAILY_PROMO = false;
const HOT_GAMES_COUNT = 12;
const WIN_QUICK_BET_POINT = [10,50,100,500,1000,10000]
const FIXED_WIN_OPTIONS = false;
const SENTRY_DSN = {"dev":"https://45bf6a72e8dfb5a0622ea4196786ac5c@o4507110666272768.ingest.us.sentry.io/4507185783308288","stag":"https://45bf6a72e8dfb5a0622ea4196786ac5c@o4507110666272768.ingest.us.sentry.io/4507185783308288","prod":"https://45bf6a72e8dfb5a0622ea4196786ac5c@o4507110666272768.ingest.us.sentry.io/4507185783308288"};
const OPEN_SABA_CRICKET = false;
const WIN_RECORD_DELAY_TIMER = 5;
const WIN_UNFOCUS_RELOAD_POPUP = true;
const SHOW_HOT_AS_PLATFORM_LIST = false;
const SHOW_LOGIN_CLOUDFLARE = false;
const LOGIN_CLOUDFLARE_KEY = {"dev":"","stag":"","prod":""};
const ENV = "production";

 export {
CDN_URL,
GLOBAL_CDN,
GLOBAL_SETTING_API,
GLOBAL_USER_API,
IMAGE_API,
MAIN_API,
WHEEL_API,
PC_URL,
WIN_URL,
HISTORY_URL,
PROTOCOL,
AGENT_CENTER_URL,
AGENT_MOBILE_URL,
GAME_SOCKET_URL,
STOCK_SOCKET_URL,
WS_PROTOCOL,
DOUBLE_SEVEN_MOBILE_URL,
MG_SLOT_MAIN_API,
T1_MAIN_API,
EVO_MAIN_API,
PP_SLOT_MAIN_API,
PG_SLOT_MAIN_API,
YGG_SLOT_MAIN_API,
EVOPLAY_SLOT_MAIN_API,
JILI_SLOT_MAIN_API,
DOUBLE_SEVEN_MAIN_API,
GALAXSYS_MAIN_API,
SPRIBE_MAIN_API,
PT_MAIN_API,
PPLIVE_MAIN_API,
ONEPLAY_MAIN_API,
PLAYSTAR_MAIN_API,
WEBLOCKCHAIN_MAIN_API,
WELOTTERY_MAIN_API,
WELIVE_MAIN_API,
TCG_MAIN_API,
YOUBET_MAIN_API,
AESEXY_MAIN_API,
SPRIBEONE_MAIN_API,
DECIMAL_SEPARATOR,
DEFAULT_CURRENCY,
TIMEZONE_CURRENCY,
DEFAULT_LANG,
FIXED_NUMBER,
MIN_DECIMAL_POINT,
MAX_DECIMAL_POINT,
HOME_HEADER_BACKGROUND_IMAGE,
BINANCE_URL,
PRODUCTION_NAME_DEV,
PRODUCTION_NAME_DISPLAY,
BASE_PROJECT,
THOUSANDS_SEPARATOR,
HEADER_SHOW_TRANS_RECORD,
ADD_PADDING_BOTTOM,
HIDE_VIP_CATEGORY,
EXTERNAL_GAME,
ACCOUNT_VERIFY_METHOD,
GCODE,
DOWNLOAD_PAGE_LOGO_FILE_EXTENTION,
IS_WALLET_IN_PERSONAL_PAGE,
HIDE_OFFLINE_BANK_CARD_DEPOSIT,
HIDE_INHOUSE_CRYPTO,
TELEGRAM_URL,
LAST_PLAY_COUNT,
GOOGLE_PLAY_DOWNLOAD_URL,
APPLICATION_VERSION,
SHOW_WITHDRAW_LIST,
SHOW_PROMOTION_DATE,
MIN_USDT_WITHDRAW_LIMIT,
MAX_USDT_WITHDRAW_LIMIT,
DOUBLE7_LOTTERY_SHOW_POPUP,
HIDE_PROFILE_PHONE,
SHOW_DEPOSIT_PROMO_IMAGE,
WIN_DISABLE_BET_TIME,
SHOW_DAILY_PROMO,
FORCE_OPEN_DAILY_PROMO,
HOT_GAMES_COUNT,
WIN_QUICK_BET_POINT,
FIXED_WIN_OPTIONS,
SENTRY_DSN,
OPEN_SABA_CRICKET,
WIN_RECORD_DELAY_TIMER,
WIN_UNFOCUS_RELOAD_POPUP,
SHOW_HOT_AS_PLATFORM_LIST,
SHOW_LOGIN_CLOUDFLARE,
LOGIN_CLOUDFLARE_KEY,
ENV,
}