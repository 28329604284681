import { requestGlobalSettingData, requestMain } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const currency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetGameTypes = (params) =>
  requestGlobalSettingData(
    "get",
    `/${currency}/game/category/bet`,
    params,
    true,
    false
  );

export const apiGetGameListByType = (params = {}, typeId) =>
  requestGlobalSettingData(
    "get",
    `/${currency}/game/${typeId}/bet`,
    params,
    true,
    false
  );

export const apiGetBetRecord = (params) =>
  requestMain(
    "get",
    `/${currency}/game/bet`,
    params,
    true,
    true
  );
