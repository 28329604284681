import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";
import WarningIcon from "./icons/WarningIcon";
import styles from "./style.module.scss";
import "./style.scss";

function CustomModal({
  isHaveCancel = true,
  isModalVisible,
  setIsModalVisible,
  maskClosable = false,
  title = "",
  content = "",
  needIcon = false,
  contentContainerStyle = "",
  closable = false,
  destroyOnClose = true,
  iconRender = () => {
    return <WarningIcon />;
  },
  okBtnText = " ",
  cancelBtnText = " ",
  showCloseButton = false,
  onOk = () => {
    setIsModalVisible(false);
  },
  onCancel = () => {
    setIsModalVisible(false);
  },
  onCloseModal = () => {},
  okBtnStyle = {},
  getContainer = () => document.body,
}) {
  const handleCloseModal = () => {
    setIsModalVisible(false);
    onCloseModal();
  };

  const renderCloseButton = () => {
    if (!showCloseButton) return null;
    return (
      <div className={styles.closeButton} onClick={handleCloseModal}>
        <CloseCircleOutlined />
      </div>
    );
  };

  return (
    <Modal
      getContainer={getContainer}
      visible={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      width={265}
      centered
      closable={closable}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
      className="custom-modal"
      bodyStyle={{ borderRadius: "8px", padding: "0", maxHeight: "60vh" }}
    >
      <div className={`${styles.customModal}`}>
        <div className={`${styles.contentBox} ${contentContainerStyle}`}>
          {renderCloseButton()}
          {needIcon ? <div className={`${styles.iconBox}`}>{iconRender()}</div> : ""}
          <div className={`${styles.title}`}>{title}</div>
          <div className={`${styles.content}`}>
            {typeof content === "function" ? content() : content}
          </div>
        </div>
        <div className={`${styles.buttonBox} ${!isHaveCancel ? styles.fullWidth : ""}`}>
          {isHaveCancel ? (
            <Button type="text" className={`${styles.cancelBtn}`} onClick={onCancel}>
              {cancelBtnText}
            </Button>
          ) : null}

          <Button
            type="primary"
            className={`${styles.okBtn} ${!isHaveCancel ? styles.fullWidth : ""}`}
            onClick={onOk}
            style={{
              ...okBtnStyle,
            }}
          >
            {okBtnText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CustomModal;
