import { Popup } from "antd-mobile";
import { useTranslation } from "react-i18next";
import BetRecord from "../../pages/BetRecord";
import AppBar from "../AppBar";
import styles from "./style.module.scss";

const WinBetRecordModal = (
  { onClose = () => {}, visible = false, destroyOnClose = false, afterClose = () => {} },
  ...props
) => {
  const { t } = useTranslation();
  const TYPE = [
    {
      id: 3,
      code: "lottery",
      name: "Lottery Game",
      sorting: 1,
    },
  ];
  const GAME = [
    {
      id: 6,
      code: "winlottery",
      name: "Win Lottery",
      data: '{"text": ["LOTTERY", "WIN LOTTERY"]}',
      sorting: 0,
      game_sub: [
        {
          id: 36,
          game_3th_id: 6,
          game_3th_sub_id: null,
          currency: "BRL",
          is_new: 0,
          is_hot: 0,
          game_3th_sub_path: null,
          maintenance_status: 0,
          status: 1,
          sorting: null,
          created_at: "2022-07-29 03:31:02",
          updated_at: "2022-07-29 03:31:02",
          code: null,
          name: null,
        },
      ],
    },
  ];

  return (
    <Popup
      {...props}
      visible={visible}
      destroyOnClose={destroyOnClose}
      afterClose={afterClose}
      position="right"
      bodyStyle={{ width: "100vw", backgroundColor: "transparent" }}
    >
      <div className={styles.rulePopupContainer}>
        <AppBar
          title={t("page.betRecord")}
          showBackButton={true}
          iconClosePadding={"4px"}
          onClickEvent={() => {
            onClose();
          }}
        />
        <div className={styles.container}>
          <BetRecord showHeader={false} searchType={TYPE} searchGame={GAME} />
        </div>
      </div>
    </Popup>
  );
};

export default WinBetRecordModal;
