import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import queryKey from "../../enumerations/queryKey";
import webSocketActionEnum from "../../enumerations/webSocketActionEnum";
import winStateEnum from "../../enumerations/winStateEnum";
import styles from "./style.module.scss";

const HOUR_SECOND = 60 * 60;
const MINUTE_SECOND = 60;

dayjs.extend(utc);
dayjs.extend(timezone);

const Countdown = ({
  selectedGameList,
  punctuationStyle = {},
  disableTimeZoneTrans,
  setCurrentGameState = () => {},
  currentGameState,
  latestNumber = undefined,
  refreshData = () => {},
  latestNumberRefetching = false,
  winColorCountdown = "",
  winColorCountdownNumbers = "",
  winColorCountdownBox = "",
  winColorPunctuation = "",
  recordTimer = 10,
}) => {
  const { isWaitPeriod, currentPeriod, setCounter } = useGameWebsocket();
  const queryClient = useQueryClient();
  const apiTimerRef = useRef();
  const reloadPeriodRef = useRef();
  const recordTimerRef = useRef();
  const [localCurrentTime, setLocalCurrentTime] = useState();
  const [localCounter, setLocalCounter] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const handleChangeGameState = (diff) => {
    if (diff <= latestNumber[0].close_time && diff !== 0) {
      if (currentGameState !== winStateEnum.DISABLE_BET_PHASE) {
        setCurrentGameState(winStateEnum.DISABLE_BET_PHASE);
      }
    } else {
      if (currentGameState !== winStateEnum.BET_PHASE) {
        setCurrentGameState(winStateEnum.BET_PHASE);
      }
    }
  };

  const countdown = useCallback(() => {
    if (currentPeriod.end && currentPeriod.current_date_time) {
      const timeStringProcess = (timeString) => {
        const regex = /\-/g;
        return timeString.replace(regex, "/");
      };
      const difference = dayjs(
        `${timeStringProcess(currentPeriod.end)}${disableTimeZoneTrans ? "" : " +00:00"}`,
      ).diff(dayjs(timeStringProcess(currentPeriod.current_date_time)), "s");
      handleChangeGameState(difference);

      setCounter(difference);
      return difference;
    }
    return 0;
  }, [currentPeriod.end, currentPeriod.current_date_time]);

  const apiCountDown = useCallback(() => {
    if (latestNumber && localCurrentTime && !latestNumberRefetching) {
      const activePeriod = latestNumber[0];
      const difference = dayjs(`${activePeriod.end} +00:00`.replace(/-/g, "/")).diff(
        dayjs(`${localCurrentTime} +00:00`.replace(/-/g, "/")),
        // dayjs().utc(),
        "seconds",
      );
      handleChangeGameState(difference);
      return difference;
    }
    return 0;
  }, [latestNumber, localCurrentTime, latestNumberRefetching]);

  const refreshRecord = (latestNumber) => {
    if (latestNumber) {
      let id = 0;
      const currentData = queryClient.getQueryData([
        queryKey.WIN_GAME_HISTORY,
        selectedGameList,
        10,
        1,
      ]);
      if (currentData?.data && currentData?.data.length > 0 && latestNumber?.period) {
        if (currentData.data[0].period !== latestNumber.period) {
          id = currentData.data[0].id;
          queryClient.setQueryData(
            [queryKey.WIN_GAME_HISTORY, selectedGameList, 10, 1],
            (oldData) => {
              return {
                ...oldData,
                data: [
                  {
                    id: id,
                    game_type: selectedGameList,
                    period: latestNumber.period,
                    lottery_number: "",
                    draw_time: latestNumber.end,
                    created_at: latestNumber.start,
                    action: webSocketActionEnum.PENDING,
                  },
                  ...oldData.data,
                ].slice(0, 20),
              };
            },
          );
        }
      }
      recordTimerRef.current = setTimeout(() => {
        queryClient.invalidateQueries([queryKey.WIN_GAME_HISTORY, selectedGameList, 10, 1]);
      }, [recordTimer * 1000]);
    }
  };

  const handleSetLocalTimer = (diff) => {
    if (isNaN(diff)) {
      setLocalCounter({ hours: "--", minutes: "--", seconds: "--" });
      return;
    }
    const hours = String(parseInt(diff / HOUR_SECOND + 100)).slice(-2);
    const minutes = String(parseInt(((diff / MINUTE_SECOND) % MINUTE_SECOND) + 100)).slice(-2);
    const seconds = String(parseInt((diff % MINUTE_SECOND) + 100)).slice(-2);
    setLocalCounter({ hours, minutes, seconds });
  };

  useEffect(() => {
    if (latestNumber) {
      setLocalCurrentTime(latestNumber[0].current_time);
    }
  }, [latestNumber, latestNumberRefetching]);

  useEffect(() => {
    let _timer;
    if (currentPeriod.end) {
      clearInterval(apiTimerRef.current);
      _timer = countdown();
      handleSetLocalTimer(_timer);
    } else {
      let _diff = apiCountDown();
      let waitTime = 1;
      apiTimerRef.current = setInterval(() => {
        _diff = Math.max(_diff - 1, 0);
        handleSetLocalTimer(_diff);
        handleChangeGameState(_diff);
        setCounter(_diff);
        if (_diff <= 0 && latestNumberRefetching === false) {
          if (waitTime <= 0) {
            refreshRecord(latestNumber ? latestNumber[0] : undefined);
            refreshData();
            waitTime = 2;
          }
          waitTime--;
        }
      }, [1000]);
    }

    return () => {
      console.debug("clearing interval");
      clearInterval(apiTimerRef.current);
    };
  }, [currentPeriod, latestNumber, latestNumberRefetching, localCurrentTime]);

  useEffect(() => {
    return () => {
      clearTimeout(recordTimerRef.current);
    };
  }, []);

  useEffect(() => {
    if (latestNumber) {
      if (!latestNumber[0].period && latestNumberRefetching === false) {
        reloadPeriodRef.current = setTimeout(() => {
          refreshData();
        }, 3000);
      }
    }
    return () => {
      clearTimeout(reloadPeriodRef.current);
    };
  }, [latestNumber, latestNumberRefetching]);
  const render = useMemo(() => {
    return (
      <div className={`${styles.countdown} ${winColorCountdown}`}>
        <div className={`${styles.countdownNumbers} ${winColorCountdownNumbers}`}>
          <div className={`${styles.countdownBox} ${winColorCountdownBox}`}>
            {isWaitPeriod === true ? " " : localCounter.hours || "-"}
          </div>
          <div style={punctuationStyle} className={`${styles.punctuation} ${winColorPunctuation}`}>
            :
          </div>
          <div className={`${styles.countdownBox} ${winColorCountdownBox}`}>
            {isWaitPeriod === true ? " " : localCounter.minutes || "-"}
          </div>
          <div style={punctuationStyle} className={`${styles.punctuation} ${winColorPunctuation}`}>
            :
          </div>
          <div className={`${styles.countdownBox} ${winColorCountdownBox}`}>
            {isWaitPeriod === true ? " " : localCounter.seconds || "-"}
          </div>
        </div>
      </div>
    );
  }, [localCounter, isWaitPeriod]);
  return render;
};

export default Countdown;
