import { SyncOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetUserWalletCache } from "../../../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { getRandomInt } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const BalanceViewer = () => {
  const cache = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const timeoutRef = useRef();
  const [isFakeReload, setIsFakeReload] = useState(false);
  const [localWalletLoading, setLocalWalletLoading] = useState(false);

  const {
    error: walletError,
    data: walletData,
    refetch,
    isFetching,
  } = useGetUserWalletCache({
    onSuccess: () => {
      setIsFakeReload(true);
    },
  });

  const onHandleDepositOpen = () => {
    navigate("/exchange?tab=deposit");
  };

  const handleReloadBalance = () => {
    if (!localWalletLoading) {
      if (isFakeReload === true) {
        setLocalWalletLoading(true);
        setTimeout(() => {
          setLocalWalletLoading(false);
        }, [750]);
        return;
      }
      if (!isFetching) refetch();
    }
  };

  useEffect(() => {
    if (isFakeReload === true) {
      const timeoutDelay = getRandomInt(5, 10);
      timeoutRef.current = setTimeout(() => {
        setIsFakeReload(false);
      }, [timeoutDelay * 1000]);
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }
  }, [isFakeReload]);

  return (
    <div className={styles.balanceContainer}>
      <div className={styles.balanceTitle}>{t("win.normal.balance")}</div>
      <div className={styles.innerContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.balance}>{`${t(
            `currency.symbol.${CURRENCY.toUpperCase()}`,
          )} ${moneyFormat(walletData?.money)}`}</div>
          <div onClick={handleReloadBalance}>
            <SyncOutlined className={styles.icon} spin={isFetching || localWalletLoading} />
          </div>
        </div>
        <div className={styles.buttonContainer} onClick={onHandleDepositOpen}>
          {t("normal.deposit")}
        </div>
      </div>
    </div>
  );
};

export default BalanceViewer;
