import { SyncOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WIN_RECORD_DELAY_TIMER } from "../../config";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import localStorageKey from "../../enumerations/localStorageKey";
import { useGetUserWalletCache } from "../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../plugins/numberFormat";
import { getRandomInt } from "../../plugins/utils";
import Countdown from "../Countdown";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const GameHeader = ({
  setRuleVisible = () => {},
  setWalletAmount = () => {},
  setCurrentGameState = () => {},
  currentGameState,
  selectedGameList,
  latestNumber = undefined,
  refreshData = () => {},
  latestNumberRefetching = false,
}) => {
  const reloadBalanceTimeout = 10000;
  const { t } = useTranslation();
  const cache = useQueryClient();
  const timeoutRef = useRef();
  const { currentPeriod, refetchData } = useGameWebsocket();
  const [localPeriod, setLocalPeriod] = useState("-");
  const [localWalletLoading, setLocalWalletLoading] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(false);
  const [localWallet, setLocalWallet] = useState(0);
  const [isFakeReload, setIsFakeReload] = useState(false);
  const navigate = useNavigate();
  const {
    data: cacheWalletData,
    refetch: refetchCache,
    isFetching: isFetchingCache,
  } = useGetUserWalletCache({
    enabled: false,
    onSuccess: () => {
      setIsFakeReload(true);
    },
  });
  // const {
  //   data: walletData,
  //   refetch: refetchWallet,
  //   isFetching: isFetchingWallet,
  // } = useGetUserWallet({
  //   enabled: false,
  // });

  const [disabled, setDisabled] = useState(false);

  const onHandleDepositOpen = () => {
    navigate("/exchange?tab=deposit");
  };

  const onHandleRuleOpen = () => {
    setRuleVisible(true);
  };
  const handleReloadCacheBalance = () => {
    if (!localWalletLoading) {
      if (isFakeReload === true) {
        setLocalWalletLoading(true);
        setTimeout(() => {
          setLocalWalletLoading(false);
        }, [750]);
        return;
      }
      refetchCache();
    }
  };

  // const handleReloadBalance = () => {
  //   refetchWallet();
  // };

  // const throttleReloadBalance = () => {
  //   setDisabled(true);
  //   handleReloadBalance();
  //   setTimeout(() => {
  //     setDisabled(false);
  //   }, reloadBalanceTimeout);
  // };

  // useEffect(() => {
  //   if (refetchData === true) {
  //     setTimeout(() => {
  //       handleReloadBalance();
  //     }, [2000]);
  //   }
  // }, [refetchData]);

  // useEffect(() => {
  //   if (walletData) {
  //     setWalletAmount(walletData.money);
  //     setLocalWallet(walletData.money);
  //   }
  // }, [walletData]);

  useEffect(() => {
    if (cacheWalletData) {
      setLocalWallet(cacheWalletData.money);
      setWalletAmount(cacheWalletData.money);
    }
  }, [cacheWalletData]);

  useEffect(() => {
    if (isFakeReload === true) {
      const timeoutDelay = getRandomInt(5, 10);
      timeoutRef.current = setTimeout(() => {
        setIsFakeReload(false);
      }, [timeoutDelay * 1000]);
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }
  }, [isFakeReload]);

  useEffect(() => {
    setLocalWalletLoading(isFetchingCache || fakeLoading);
  }, [isFetchingCache, fakeLoading]);

  useEffect(() => {
    setLocalPeriod(currentPeriod?.period || (latestNumber && latestNumber[0]?.period) || "-");
  }, [currentPeriod.period, latestNumber]);

  useEffect(() => {
    const _cacheWallet = localStorage.getItem(localStorageKey.WALLET);
    if (_cacheWallet) {
      setLocalWallet(_cacheWallet);
      setWalletAmount(_cacheWallet);
    } else {
      refetchCache();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.balanceContainer}>
          <div className={styles.balanceTitle}>{t("win.normal.mybalance")}</div>
          <div className={styles.balanceInnerContainer}>
            <div className={styles.balance}>{`${t(
              `currency.symbol.${CURRENCY.toUpperCase()}`,
            )} ${moneyFormat(localWallet)}`}</div>
            <div
              className={styles.reload}
              onClick={handleReloadCacheBalance}
              spin={localWalletLoading}
            >
              <SyncOutlined className={`${styles.icon}`} spin={localWalletLoading} />
            </div>
          </div>
        </div>
        <div className={styles.periodTitle}>{t("win.normal.period")}</div>
        <div className={styles.period}>{localPeriod}</div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonDeposit} onClick={onHandleDepositOpen}>
            {t("normal.deposit")}
          </div>
          <div className={styles.buttonRule} onClick={onHandleRuleOpen}>
            {t("win.normal.rule")}
          </div>
        </div>
        <Countdown
          selectedGameList={selectedGameList}
          refreshData={refreshData}
          setCurrentGameState={setCurrentGameState}
          currentGameState={currentGameState}
          latestNumber={latestNumber}
          latestNumberRefetching={latestNumberRefetching}
          recordTimer={WIN_RECORD_DELAY_TIMER}
        />
      </div>
    </div>
  );
};

export default GameHeader;
