const localStorageKey = {
  DEFAULT_CURRENCY: "metaDefaultCurrency",
  LANG_SETTING: "metaLangSetting",
  TOKEN: "token",
  USER_INFO: "meta188",
  EMAIL_VERIFY_SEND_TIME: "sendVerifyCodeEmail",
  IS_APP: "isApp",
  CURRENT_APP_VERSION: "current_app_version",
  RECENT_PLAY: "recent_play",
  SKIP_TUTORIAL: "skip_tutorial",
  LOCAL_SETTINGS: "local_settings",
  INVITE_CODE: "invite_code",
  CHECKBOX: "checkbox",
  NOTICE_READ_RECORD: "noticeReadRecord",
  DAILY_REWARD_DATE: "daily_reward_date",
  WALLET: "wallet",
};

export default localStorageKey;
