import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetBetRecord, apiGetGameListByType, apiGetGameTypes } from "../service";

export const useGetGameTypes = (options) => {
  return useQuery(
    [queryKey.BET_RECORD_GAME_TYPES],
    () => apiGetGameTypes().then((res) => res.data.data),
    {
      ...options,
    },
  );
};
export const useGetGameList = (options, typeId) => {
  return useQuery(
    [queryKey.BET_RECORD_GAME_LIST_BY_TYPE, typeId],
    () => apiGetGameListByType({}, typeId).then((res) => res.data.data),
    {
      ...options,
    },
  );
};

export const useGetBetRecord = (options, params) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === "") {
      delete params[key];
    }
  });
  const _arrParams = Object.keys(params).map((key) => params[key]);
  return useInfiniteQuery(
    [queryKey.BET_RECORD, ..._arrParams],
    ({ pageParam = 1 }) =>
      apiGetBetRecord({ ...params, page: pageParam }).then((res) => {
        console.debug("res", res);
        return res.data.data;
      }),
    {
      cacheTime: 0,
      ...options,
    },
  );
};
